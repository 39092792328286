<template>
  <div class="v-stack h-stretch gap-3" v-if="car">
    <div ref="overlay" class="overlay">
      <div class="card dynamic">
        <div class="content mt-3">
          <div class="v-stack h-stretch gap-5">
            <div>
              Are you sure you want submit the trip? You won't be able to change
              it later!
            </div>
          </div>
        </div>
        <div class="footer gap-3 h-space-between mt-3">
          <button @click="dismiss()" class="submit">Cancel</button>
          <button @click="submit()" class="edit">Submit</button>
        </div>
      </div>
    </div>

    <a class="heading-title-2">Car Info</a>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <div class="pane-horizontal gap-3">
            <label class="text-right">Name:</label>
            <div class="text-left">{{ car.name || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Gas Price:</label>
            <div class="text-left">{{ gasPrice }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Fuel Consumption per 100km:</label>
            <div class="text-left">{{ car.consumption || "Not Set" }}</div>
          </div>
          <div class="pane-horizontal gap-3">
            <label class="text-right">Depriciation value CZK per km:</label>
            <div class="text-left">{{ car.useRatePerKm || "Not Set" }}</div>
          </div>
        </div>
      </div>
    </div>
    <a class="heading-title-2">Trip Info</a>
    <div class="card light mn">
      <div class="content h-stretch">
        <div class="v-stack gap-3 h-stretch">
          <label class="text-left">Start Date</label>
          <InputDatePicker v-model="trip.startDate"></InputDatePicker>
          <label class="text-left">End Date</label>
          <InputDatePicker v-model="trip.endDate"></InputDatePicker>
          <label class="text-left">Km</label>
          <input type="number" v-model="trip.km" />
          <label class="text-left">Price:</label>
          <input type="number" v-model="price" disabled />
          <label class="text-left">Description</label>
          <textarea v-model="trip.description"></textarea>
          <div class="h-stack h-end gap-3">
            <button class="submit" @click="reserve()">Reserve</button>
            <button class="submit" @click="showSubmitDialog()">Submit</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import utils from "@/utils";
import InputDatePicker from "@/components/calendar/InputDatePicker.vue";

export default {
  props: ["id"],
  components: {
    InputDatePicker,
  },
  data() {
    return {
      car: null,
      trip: {
        startDate: moment().toISOString(),
        endDate: moment().toISOString(),
        km: 0,
        description: "",
        submitted: false,
      },
      gasPrice: 0,
    };
  },
  computed: {
    price() {
      return utils.carRentalObject.price(this.car, this.trip, this.gasPrice);
    },
  },
  methods: {
    ...mapActions(["getCar", "addTrip", "getGasPrice"]),
    showSubmitDialog() {
      this.$refs.overlay.classList.add("show");
    },
    submit() {
      this.dismiss();
      this.trip.submitted = true;
      this.trip.price = this.price;
      this.reserve();
    },
    reserve() {
      this.trip.car = this.id;
      this.addTrip(this.trip)
        .then(() => {
          this.$router.go(-1);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dismiss() {
      this.$refs.overlay.classList.remove("show");
    },
  },
  mounted() {
    this.getCar(this.id)
      .then((car) => {
        this.car = car;
      })
      .catch((error) => {
        console.log(error);
      });
    this.getGasPrice()
      .then((gasPrice) => {
        if (gasPrice) {
          this.gasPrice = gasPrice.price;
        }
      }).catch((error) => {
        console.log(error)
      });
  },
};
</script>
